import React from "react"
import Fade from "react-reveal/Fade"
import styled from "styled-components"
import useWindowSize from "../components/hooks/useWindowSize"

import { AiFillLinkedin, AiFillGithub } from "react-icons/ai"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"

import agl from "../images/agl-logo.png"
import innowell from "../images/innowell.png"
import insight from "../images/insight.png"
import superkids from "../images/superkids.png"
import madecomfy from "../images/madecomfy.png"
import tribal from "../images/tribal.png"
import bn from "../images/bn.svg"
import tm from "../images/tm.svg"

const content = [
  {
    image: tm,
    company: "Thinkmill",
    role: "Senior software developer",
    time: { start: "Apr 2022", end: "Apr 2023" },
    company_desc:
      "a globally renowned design and development consultancy, specializes in delivering top-notch frontend design systems and architectural solutions.",
    work:
      "During my tenure at Thinkmill, I collaborated with high-profile clients to develop innovative user interfaces for their products using cutting-edge React and Typescript technologies. Additionally, I played a significant role in constructing expansive design systems.",
    highlights: [
      "I have worked on large scale React applications.",
      "Create design system components and documentation.",
      "Large scale code reviews and code standards.",
    ],

    tech: ["Javascript", "html", "css", "firebase", "React"],
  },
  {
    image: bn,
    company: "Bailey Nelson",
    role: "Frontend developer",
    time: { start: "Jul 2020", end: "Apr 2022" },
    company_desc: "a leading eyewear manufacturer and retailer",
    work:
      " I was responsible for developing a cohesive website tailored for four distinct countries by leveraging the existing Shopify API, Prismic CMS, and GatsbyJS, all deployed on AWS Amplify.",
    highlights: [
      "Maintaining and introducing new features like 3D experiences",
      "Audience tracking",
      "Added unity and integration tests in Jest and Cypress.",
    ],

    tech: ["Javascript", "html", "css", "firebase", "React"],
  },
  {
    image: insight,
    company: "Insight Timer",
    role: "Frontend developer",
    time: { start: "Feb 2020", end: "May 2020" },
    company_desc: "is a meditation app with 15 million user worldwide",
    work:
      "I work in the Teams Platform, a digital product for companies, helping to kickstart the project and deliver design and functionality",
    highlights: [
      "I have worked on a React app.",
      "Used a Firebase API and Database.",
      "Added unity and integration tests in Jest and Cypress.",
      "Added lint tools and code standards.",
    ],

    tech: ["Javascript", "html", "css", "firebase", "React"],
  },
  {
    image: innowell,
    company: "Innowell",
    role: "Frontend developer",
    time: { start: "Jan 2018", end: "May 2020" },
    company_desc:
      "is a partnership between University of Sydney and Pwc, working towards creating better mental health for young adults, creating tools where clinicians can better assess patients and patients can connect and enter better information about their mental health.",
    work:
      "I've worked with backend and design teams to deliver features for the users, being resposible to deliver features from specs to final user,  I work active in create a better user experice accross all devices",
    highlights: [
      "Worked in a React application using GraphQL and Relay.",
      "Built  components and added to a design system using Storybook.",
      "Create interactive forms and graphs.",
      "Used Flow type and ES6.",
      "Improved accessibility and mobile versions of the platform.",
    ],
    tech: ["Javascript", "html", "css", "Rails", "graphQl", "React"],
  },
  {
    company: "MadeComfy",
    image: madecomfy,
    role: "Frontend developer",
    company_desc: "is Airbnb management company with more than 350 properties.",
    work:
      "I've worked with backend and design teams to deliver features for the users, being responsible to deliver features from specs to final user, I work active in create a better user experience across all devices",
    highlights: [
      "Created a component library and a design system using StorybookJs",
      "Used Gatsby for a static generated website.",
      "Improved loading times and SEO for marketing website.",
      "Worked on a React dashboard for users.",
      "Added unity and integration tests.",
    ],
    time: { start: "Jan 2017", end: "Jan 2018" },
    tech: ["Javascript", "html", "css", "graphQl", "Aws", "React"],
  },
  {
    image: tribal,
    company: "Tribal DDB",
    time: { start: "Oct 2016", end: "Dec 2016" },
    role: "Fullstack developer",
    company_desc: "is a global network of interactive agencies.",
    work:
      "I worked with the creative direction delivering new solutions and ideas for project like Ford and DDB",
    highlights: [
      "Created a bookmark that replace all ads on the page",
      "Added features to the company website.",
      "Added unity and integration tests.",
    ],
    tech: ["Javascript", "html", "css", "React"],
  },
]

const Grid = styled.div`
  display: grid;
  grid-template-columns: 49% 2% 49%;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }

  p {
    font-size: 0.5em;
  }
`

const Wrapper = styled.div`
  align-items: center;
  background-color: #fff;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 500px;
  width: 100%;
  @media (max-width: 768px) {
    min-height: 100px;
  }
`

const Line = styled.div`
  border-left: 6px solid #e5e5e5;
  height: 100%;
  background-color: #fff;
  @media (max-width: 768px) {
    display: none;
  }
`

const Dot = styled.div`
  background-color: #e5e5e5;
  bottom: 51%;
  height: 4px;
  left: -12px;
  position: relative;
  width: 30px;
`

const Thanks = styled.div`
  padding: 2em 7em;
  margin-bottom: 200px;
  background-color: #fff;

  @media (max-width: 768px) {
    padding: 1em;
  }

  p {
    font-size: 0.6em;
    text-align: center;
    margin: 0;
    font-size: 24px;
    line-height: 40px;
    font-weight: 600;

    a {
      border-bottom: 2.5px solid black;
      :hover {
        background-color: rgb(203, 228, 255);
      }
    }
  }
`

const IntroStyles = styled.div`
  font-size: 0.6em;
  margin-bottom: 1em;
  background-color: #fff;
  .flex {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }
  .text {
    color: #303030;
    font-family: "Bitter";
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 30px;
    overflow-wrap: break-word;
    padding: 1em;
    text-align: justify;
    width: 100%;
  }
`

const Image = ({ image, title }) => (
  <Wrapper>
    <Fade top>
      <img
        src={image}
        alt={title}
        style={{
          maxWidth: "10rem",
        }}
      />
    </Fade>
  </Wrapper>
)

const TextWrapper = styled.div`
  margin: 1em;
  line-height: 28px;
  color: #303030;

  p {
    font-size: 18px;
    font-family: "Bitter";
    line-height: 25px;
    overflow-wrap: break-word;
    color: #303030;
    font-weight: 500;
    letter-spacing: 0;
  }
  h3 {
    font-size: 0.6em;
    line-height: 50px;
    margin: 0;
  }

  li {
    font-size: 0.5em;
    line-height: 1em;
    font-family: "Bitter";
    line-height: 20px;
    font-weight: 400;
    overflow-wrap: break-word;
    color: #303030;
  }
  small {
    font-size: 13px;
    padding: 0;
    margin: 0;
    position: relative;
    bottom: 18px;
  }
`

const Text = ({
  text,
  company,
  side,
  time,
  role,
  company_desc,
  work,
  highlights,
}) => (
  <Wrapper style={{ flexDirection: "column" }}>
    <Fade bottom>
      <TextWrapper>
        <h3>{role}</h3>
        <small>
          {time.start}- {time.end}
        </small>
        <p>
          {company} {company_desc}
        </p>

        <p>{work}</p>
        <ul>
          {highlights.map(i => (
            <li>{i}</li>
          ))}
        </ul>
      </TextWrapper>
    </Fade>
  </Wrapper>
)

const Intro = () => (
  <IntroStyles>
    <div className="flex">
      <div className="text">
        I started dabbling in web development when I was just 15, creating
        websites for small businesses and friends. Although I graduated with a
        degree in Tourism, I've been involved in various digital projects with
        the computer science department and have even worked in e-tourism for 5
        years. I'm proud to be a General Assembly alum and mentor in Sydney. My
        specialty lies in working with JavaScript, primarily focusing on
        frontend development with tools like React and GraphQL. I've also
        tackled projects using Node.js, Express, Serverless, and NextJS.
      </div>

      <div className="text">
        Design and user interaction are my jam, and I'm always striving to build
        stunning, functional web components that enhance user experience. I've
        been working with web development since I'm 15 years old, I developed
        websites for small bushiness and friends. I graduated in Tourism but I
        worked on the many different digital projects with the computer science
        department, I also worked with e-tourism for 5 years.
      </div>
    </div>
  </IntroStyles>
)

const Stack = styled.div`
  display: flex;
  justify-content: center;
  margin: 3em 0;

  @media (max-width: 768px) {
    justify-content: space-around;
    margin-bottom: 3em;
  }
`

const Container = styled.div`
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
`

const Work = () => {
  const size = useWindowSize()
  const isMobile = size.width < 600

  return (
    <Layout>
      <Container>
        <SEO title="Work" />
        <Intro />
        <Stack>
          <a
            href="https://www.linkedin.com/in/lucas-v-56462224/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <AiFillLinkedin size="2em" />
          </a>
          <a
            href="https://github.com/LucasVilela"
            rel="noreferrer noopener"
            target="_blank"
          >
            <AiFillGithub size="2em" />
          </a>
        </Stack>

        {!isMobile && (
          <Grid>
            {content.map((i, index) => {
              const isOdd = index % 2 === 0
              return (
                <>
                  {isOdd ? (
                    <Text
                      text={i.text}
                      company={i.company}
                      time={i.time}
                      role={i.role}
                      company_desc={i.company_desc}
                      work={i.work}
                      highlights={i.highlights}
                    />
                  ) : (
                    <Image image={i.image} />
                  )}
                  <div>
                    <Line />
                    <Dot />
                  </div>
                  <div>
                    {isOdd ? (
                      <Image image={i.image} />
                    ) : (
                      <Text
                        text={i.text}
                        company={i.company}
                        time={i.time}
                        role={i.role}
                        company_desc={i.company_desc}
                        work={i.work}
                        highlights={i.highlights}
                      />
                    )}
                  </div>
                </>
              )
            })}
          </Grid>
        )}

        {isMobile &&
          content.map((i, index) => {
            return (
              <div style={{ margin: " 2em 0" }}>
                <Fade bottom>
                  <Image image={i.image} />
                  <Text
                    text={i.text}
                    company={i.company}
                    time={i.time}
                    role={i.role}
                    company_desc={i.company_desc}
                    work={i.work}
                    highlights={i.highlights}
                  />
                </Fade>
              </div>
            )
          })}

        <Thanks>
          <p>
            Thanks for checking out my work experience, if you have a good
            opportunity let's get in touch!! You can{" "}
            <a href="mailto:fleminglucas@gmail.com">email me</a> or just fill
            this out and I will send my CV to you!
          </p>

          <ContactForm />
        </Thanks>
      </Container>
    </Layout>
  )
}

export default Work
